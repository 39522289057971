.icon_pack_wrapper {
  display: flex;
  background-color: transparent;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 80vw;
}
.icon_pack_main_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.icon_set1 {
  width: 30vw;
  height: 30vh;
  padding: 2rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  border-radius: 1rem;
  border-top: 5px solid #f00946;
  border-bottom: 5px solid #f00946;
}

.icon_top {
  font-size: 4rem;
  color: #f00946;
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 1rem;
  padding-top: 2rem;
  
  
}

.icon_title {
  color: whitesmoke;
  font-size: 1.5rem;
  text-align: center;
  padding-top: 1rem;

}

@media screen and (max-width: 768px) {
  .icon_set1 {
  width: 70vw;
  height: fit-content;
  padding: 2rem;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  border-radius: 1rem;
  border-top: 5px solid #f00946;
  border-bottom: 5px solid #f00946;
  }

  .icon_top {
    font-size: 3rem;
    color: #f00946;


    background-color: whitesmoke;
    border-radius: 1rem;
    padding: 1rem;
  }

  .icon_title {
    color: white;
    padding-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
}
