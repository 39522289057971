.contactus_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
background-color: #242424;

}

.contactus_text_area {
    max-width: 100vw;
    width: 50vw;
    margin-top: 10vh;
    border-radius: 1rem;
    background-color: rgba(54, 69, 79, 0.5);
    padding: 2rem;
    margin-bottom: 10vh;


}

.heading_contactus {
    font-size: 3rem;
    color: #f00946;
    text-align: center;
    letter-spacing: 3px;
}

.small_text {
    color: whitesmoke;
    font-size: 1rem;
    text-decoration: underline;
}

.large_text {
    color: whitesmoke;
    font-size: 2rem;
    letter-spacing: 1px;
}

.phoneNumber_text {
    background-color: #f00946;
    padding: 10px;
    border-radius: 1rem;
    margin: 1rem;

}


@media screen and (max-width: 960px) {

    .contactus_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;


    }

    .contactus_text_area {
        max-width: 90vw;
        width: 100vw;
        margin-top: 10vh;
        border-radius: 1rem;
        background-color: rgba(54, 69, 79, 0.5);
        padding: 2rem;
        margin-bottom: 10vh;


    }

    .heading_contactus {
        font-size: 2rem;
        color: whitesmoke;
        text-align: center;
        letter-spacing: 3px;
    }

    .small_text {
        color: whitesmoke;
        font-size: 0.7rem;
        text-decoration: underline;
    }

    .large_text {
        color: whitesmoke;
        font-size: 1rem;
        letter-spacing: 1px;
    }

    .phoneNumber_text {
        background-color: rgba(54, 69, 79, 1);
        padding: 10px;
        border-radius: 1rem;
        margin: 1rem;
       

    }

}