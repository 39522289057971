.information_Wrapper {
  margin-top: 2rem;
  background-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  max-width: 90vw;
  width: 90vw;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.text_information {
  font-size: 1.5rem;
  padding: 1rem;
text-align: center;
color: white;
  max-width: 50%;

}
.title_information{
    align-items: center;
    text-align: center;
    margin: 2rem;
    
}
.image_wrapper {
  object-fit: fill;
  align-items: center;
  vertical-align: middle;
  
  border-radius: 1rem;
}

.header_section{
    color: whitesmoke;
}
.image_information {
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  
  
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

@media screen and (max-width: 960px) {
    .information_Wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image_information {
        border-radius: 1rem;
      }
      
      .title_information{
        text-align: center;
        justify-content: center;
        font-size: 2rem;
      }
      .text_information {
        font-size: 1.5rem;
        padding: 1rem;
        text-align: center;
        color: white;
        max-width: 100%;
      
      }

}