.information_Wrapper_services {
  margin-top: 2rem;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  max-width: 70vw;
  width: 70vw;
  border-radius: 1rem;
  margin-bottom: 2rem;
}

.text_information_services {
  font-size: 1.5rem;
  padding: 1rem;
text-align: center;
color: white;
  max-width: 50%;

}
.title_information_services{
    align-items: center;
    text-align: center;
    margin: 2rem;
    color: #f00946;
    
}
.image_wrapper_services {
  object-fit: fill;
  align-items: center;
  vertical-align: middle;
  
  border-radius: 1rem;
}

.header_section_services{
    color: whitesmoke;
}
.image_information_services {
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  align-items: center;
  
  
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}

@media screen and (max-width: 960px) {
  .information_Wrapper_services {
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: 1s all ease-in-out;
      max-width: 100vw;
      width: 100vw;
  }

  .image_information_services{
      border-radius: 1rem;
      transition: 1s all ease-in-out;
    }
    
    .title_information_services {
      text-align: center;
      justify-content: center;
      font-size: 2rem;
      transition: 1s all ease-in-out;
    }
    .text_information_services{
      font-size: 1.5rem;
      padding: 1rem;
      text-align: center;
      color: white;
      max-width: 100%;
      transition: 1s all ease-in-out;
    
    }

}