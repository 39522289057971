.main_wrapper{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #242424;
    /*background-image: url('./backdropimage.jpg');*/
    background-size: cover;
    background-repeat: no-repeat;
   
    padding-top: 2rem;
}



.heading_wrapper>h1{
    font-size: 3rem;
}

.small{
    width: 20vw;
    color: #9b111e;
    background-color: #9b111e;
    
}