video {
    object-fit: cover;
    width: 100%;
    height: 110%;
    position: fixed;
    z-index: -1;
    opacity: 1;
    background-color: #242424;
  }
  
  .backdrop {
    object-fit: cover;
    width: 100%;
    height: 110%;
    position: fixed;
    z-index: -1;
    opacity: 1;
    background-color: #242424;
  }
  
  .hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    opacity: 1;
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: cover;
  }
  
  .logo_hero {
    align-items: center;
    object-fit: cover;
    max-width: 10%;
    padding-top: 1rem;
    margin-right: 40px;
    padding-left: 10px;
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .Hero_Heading {
    color: #fff;
    font-size: 5rem;
    
  }
  
  .Hero_Heading_2 {
    color: #fff;
    font-size: 2.5rem;
   align-items: center;
   text-align: center;
  }
  
  .Hero_Heading_3 {
    color: #fff;
    font-size: 3rem;
  }
  
  .hero-container-2 {
    position: absolute;
    display: flex;
    top: 20vh;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    transition: all 0.8s ease-in-out;
  
  }
  
  .hero-container-2:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
    object-fit: cover;
  
    transition: all 0.8s ease-in-out;
    background-color: rgba(0, 0, 0, 0.6);
    ;
  }
  
  
  .hero-container>h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  
  .hero-btns {
    display: flex;
    flex-direction: row;
  
    align-items: center;
    justify-content: center;
  
    width: 60%;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container>h1 {
      font-size: 70px;
      margin-top: 0px;
    }
  
    .Hero_Heading {
      color: #fff;
      font-size: 3rem;
      text-align: center;
    }
  
    .Hero_Heading_2 {
      color: #fff;
      font-size: 1.5rem;
      text-align: center;
      width: 100%;
      padding: 1rem;
    }
  
    .Hero_Heading_3 {
      color: #fff;
      font-size: 1rem;
      text-align: center;
    }
  
    .hero-container {
      height: 92vh;
    }
    .hero-container-2:hover {
      background-color: rgba(0, 0, 0, 0)
    }
  
    .hero-btns {
      display: flex;
      flex-direction: column;
    
  
    }
    .logo_hero {
  
      max-width: 20%;
  
    }
  
  
  }
  
  @media screen and (max-height: 500px) {
    .hero-btns {
      display: flex;
      flex-direction: row;
    
  
    }
  
    .logo_hero {
      padding-top: 10px;
      max-width: 10%;
  
    }
  
  }
  
  